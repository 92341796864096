<template>
  <div>
    <p v-if="!isShowButton">
      {{ row.info.total_evaluations_visibility ? 'Ознакомлен ' : 'Не ознакомлен ' }}
    </p>
    <el-popconfirm
      v-if="isShowButton"
      confirm-button-text="Да"
      cancel-button-text="нет"
      icon="el-icon-question"
      title="Вы уверены, что хотите ознакомить с оценкой пользователя?"
      @confirm="enableTotalEvaluationsVisibility(row)"
    >
      <template #reference>
        <el-button
          type="warning"
          size="mini"
          :loading="loading"
        >
          Ознакомить сотрудника с оценкой
        </el-button>
      </template>
    </el-popconfirm>
  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";

export default {
  name: "total-evaluations-visibility-column-template",
  computed: {
    isShowButton() {
      return !this.row.info.total_evaluations_visibility
        && this.$canAndRulesPass(this.row, 'card.total_evaluations_visibility_update')
        && this.row.status === this.$constants.card.statuses.STATUS_COMPLETED;
    },
  },
  props: {
    row: {type: Object, required: true},
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    enableTotalEvaluationsVisibility(card) {
      this.loading = true;

      requestSender('post', 'card/enable-total-evaluations-visibility', {
        card_id: card.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Статус выставлен',
            message: 'Итоговые оценки успешно открыты'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('load-cards');
        });
    },
  }
}
</script>

<style></style>
